/** 自主仓出租页面 */
import React, { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Tooltip, Input, Modal, Button, Row, Col,Icon, Select, message } from 'antd';
import './index.less';
import { MyQrCode, MainMenuItem, AddressInMap } from '../../components/common';
import { getPageData } from './../../util/utils';
import Service from './../../util/request';
import SearchIcon from './../../images/search-icon.png'
import ArrowIcon from './../../images/left-arrow-icon.png';
import WechatH5Image from './../../images/wechat-h5.jpg';
import { getModuleData } from './../home/sections';
import { SlideBanner } from './../home/sections';
import { SearchOutlined } from '@ant-design/icons';
const { Search } = Input;
const { Option } = Select;

export const getBannerImage = (data) => {
  let imageUrl = '';
  let dataItems = data.cmsModuleInfoVos;
  if (dataItems && dataItems.length > 0) {
    let images = dataItems[0].images || [];
    if (images && images.length > 0) {
      imageUrl = images[0];
    }
  }
  return imageUrl;
}

const QrCodeButton = (props) => {
  const [showQRCode, setShowQRCode] = useState(false);
  const [qrCodeImage, setQRCodeImage] = useState(null);
  const getQRCodeImage = () => {
    if (qrCodeImage) {
      setShowQRCode(!showQRCode);
      return;
    }
    let storeId = props.data.storeId;
    Service.post(`/official/qrWxCode?storeId=${storeId}`).then((res) => {
      let { code, data } = res;
      if (code == 200) {
        setQRCodeImage(data)
        setShowQRCode(true)
      }
    })
  }
  return (
    <div className="get-store-qr-code-container">
      <div className="button" onMouseOver={getQRCodeImage} onMouseLeave={(e) => { setShowQRCode(false) }}>{<FormattedMessage id="warehouse.orderBtn.text" />}</div>
      {/* {showQRCode && <div className="qr-code-image"><img src={qrCodeImage} /><div className='qr-code-message'><FormattedMessage id="warehouse.orderBtn.textMessage" /></div></div>} */}
      {showQRCode && <div className="qr-code-image"><img src={qrCodeImage} />
        <div className='qr-code-message'>
          <FormattedMessage id="warehouse.orderBtn.textMessage" />
        </div>
      </div>}
      {showQRCode && <div className="qr-code-image-mask" onClick={(e) => { setShowQRCode(false) }}></div>}
    </div>
  )
}
function WarehouseForRent(props) {
  const [pageData, setPageData] = useState({
    bannerData: {},
    warehouseTypes: { dataItems: [] },
    processData: { dataItems: [] },
    storeSearchData: {}
  })
  const [storeList, setStoreList] = useState([]);
  const [fullStoreList, setFullStoreList] = useState([]);

  const [showNoResMsg, setShowNoResMsg] = useState(false);
  const [pagination, setPagination] = useState({ page: 1 });
  const [cityList, setCityList] = useState([]);
  const [searchCity, setSearchCity] = useState('杭州市');
  const [searchAddress, setSearchAddress] = useState('');
  const [addressList, setAddressList] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(true);
  // const [showCityList, setShowCityList] = useState(false);
  const [showCityList, setShowCityList] = useState({
    visible2: false
  })
  const [addressModal, setAddressModal] = useState({
    visible: false
  })
  const searchInput = React.createRef();
  useEffect(() => {
    getCities();
    let initSearchAddress = props.location.state?.address;
    let address;
    if (initSearchAddress == undefined || initSearchAddress == null) { //直接点击header导航跳转到当前页面
      address = searchAddress;
      getStores({ city: searchCity });
    } else { //通过首页banner的搜索跳转到当前页面
      address = initSearchAddress;
      getStores({ address });
      setSearchCity('请选择城市')
    }
    setSearchAddress(address)
    getPageData({
      pageType: 2,
      lang: props.intl.locale
    }).then((res) => {
      let { code, data, msg } = res;
      if (code == 200) {
        // setPageData(data || [])
        if (data && data.length > 0) {
          let bannerData = getModuleData(data, 1),
            warehouseTypes = getModuleData(data, 2),
            processData = getModuleData(data, 3),
            storeSearchData = getModuleData(data, 4);
          setPageData({
            bannerData: {
              showModule: bannerData.isCheck == 1,
              ...bannerData
            },
            warehouseTypes: {
              showModule: warehouseTypes.isCheck == 1,
              title: warehouseTypes.title,
              dataItems: warehouseTypes.cmsModuleInfoVos
            },
            processData: {
              showModule: processData.isCheck == 1,
              title: processData.title,
              dataItems: processData.cmsModuleInfoVos
            },
            storeSearchData: {
              showModule: storeSearchData.isCheck == 1,
              title: storeSearchData.title
            }
          });
          let hash = props.location?.hash;
          if (hash) {
            setTimeout(() => {
              let anchor = document.createElement('a');
              anchor.setAttribute('href', hash);
              anchor.click();
            }, 1000)
          }
        }
      }
    }).catch((res) => {
      console.log(res);
    })
  }, [props.intl.locale])

  const getCities = () => {
    Service.get('/official/store-city').then((res) => {
      let { code, data } = res;
      if (code == 200) {
        setCityList(data)
      }
    })
  }
  const getStores = ({ address, page, city }) => {
    let params = {
      page: page || pagination.page,
      size: 20
    }
    if (!!city) { //按城市查询
      params = {
        ...params,
        address: city
      }
    }
    if (!!address || address == '') { //按地址查询
      params = {
        ...params,
        address
      }
    }
    Service.post('/official/store', params).then((res) => {
      let { code, data } = res;
      if (code == 200) {
        let { pagination, result } = data;
        if (result.length == 0) { //根据查询条件无结果时，显示无结果的信息，并获取全部仓库信息显示在下方
          // message.error('该城市暂无营业门店，请选择其他城市')
          setShowNoResMsg(true)
          getAllStores();
          return;
        }
        let newStoreList;
        if (showNoResMsg) { //之前的查询条件查不到结果
          newStoreList = [];
        } else {
          newStoreList = [].concat(storeList);
        }
        setShowNoResMsg(false)
        if (pagination.page == 1) {
          newStoreList = result;
        } else {
          newStoreList = newStoreList.concat(result)
        }
        setStoreList(newStoreList);
        setPagination(pagination);
      }
    })
  }
  const getAllStores = () => {
    setStoreList([]);
    let params = {
      address: '',
      page: 1,
      size: 1000
    }
    Service.post('/official/store', params).then((res) => {
      let { code, data } = res;
      if (code == 200) {
        let { pagination, result } = data;
        let newStoreList = [].concat(result);
        setStoreList(newStoreList);
        setPagination(pagination);
      }
    })
  }
  const getMoreStore = () => {
    if (searchCity && searchCity !== '请选择城市') {
      getStores({ page: pagination.page + 1, city: searchCity })
    } else {
      getStores({ page: pagination.page + 1, address: searchAddress })
    }
  }
  const getWechatAppUrl = (item, index) => {
    let list = [].concat(storeList);
    Service.post(`/official/qrWxCode?storeId=${item.storeId}`).then((res) => {
      let { code, data } = res;
      if (code == 200) {
        list[index] = { ...list[index], qrCordImage: data };
        setStoreList(list)
      }
    })
  }
  const generateWechatAppCode = (item) => {
    if (item.qrCordImage) {
      return (<div className="wechat-app-container">
        <img src={item.qrCordImage} />
      </div>)
    }
    return null;
  }
  const onInputChanged = (e) => {
    let value = e.target.value;
    if (value !== null) {
      Service.post('/official/store-map', { keyword: value }).then((res) => {
        console.log(res);
        let { code, data } = res;
        if (code == 200) {
          setAddressList(data);
        }
      })
    }
  }
  const onKeyUp = (e) => {
    console.log(e)
    if (e.keyCode == 13) { //enter键
      let searchStr = e.currentTarget.value || '';
      getStores({ address: searchStr, page: 1 })
      afterAddressSearch(searchStr)
    }
  }

  const searchByAddress = (address) => {
    getStores({ address, page: 1 });
    afterAddressSearch(address)
  }
  const hideInput = () => {
    setAddressList([])
    setShowSearchInput(true)
    setShowCityList(false)
  }
  
  // const clickSearchIcon = () => {
  //   console.log('wewewew')
  //   if (showSearchInput) {
  //     let searchStr = searchInput.current.state.value || '';
  //     getStores({ address: searchStr, page: 1 });
  //     afterAddressSearch(searchStr)
  //   } else {
  //     setShowSearchInput(true)
  //   }
  // }
  const afterAddressSearch = (address) => {
    setSearchCity('请选择城市')
    setSearchAddress(address)
    hideInput()
  }
  const hideAddressList = (e) => {
    e.stopPropagation();
    e.preventDefault();
    hideInput([]);
  }
  const hideCityList = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowCityList(false)
  }
  const searchByCity = (city)=>{
    setSearchAddress('');
    setSearchCity(city)
    getStores({ city, page: 1 });
    setShowCityList(false)
  }
  const closeModal = ()=>{
    setAddressModal({
      visible: false
    })
  }
  const viewAddressInMap = (item)=>{
    setAddressModal({
      visible: true,
      latitude: item.lat,
      longitude: item.lng
    })
  }
  const viewAddress = ()=>{
    setShowCityList({
      visible2: true
    })
  }
  const closeModal2 = ()=>{
     setShowCityList({
      visible: false
    })
  }

  return (
    <div className="warehouse-for-rent-page">
      {pageData.bannerData.showModule && <div className="section page-banner">
        <SlideBanner data={pageData.bannerData} />
        <div className="banner-content">
          <div className="fix-width">
            <div className="erweima-container">
              {/* <MyQrCode url="https://www.baidu.com" size={240} /> */}
              <img src={WechatH5Image} alt="微信公众号二维码" />
              <div className="note-text"><FormattedMessage id="warehouse.wechat.code.msg" /></div>
            </div>
          </div>
        </div>
      </div>}
      {pageData.warehouseTypes.showModule && <div className="section warehouse-types fix-width">
        <div className="title newTitle" >{pageData.warehouseTypes.title}</div>
        <div className="type-list">
          {pageData.warehouseTypes.dataItems.map((item,index) => {
            return (
              <div className="type-item" key={index}>
                <div className="type-size text-ellipsis" dangerouslySetInnerHTML={{ __html: item.imageFirstDesc }}></div>
                <img className='newImg' src={item.images[0]} alt="仓型图片" />
                <div className="type-capacity text-ellipsis">{item.imageSecondDesc}</div>
              </div>
            )
          })}
        </div>
      </div>}
      {pageData.processData.showModule && <div className="section rent-procedure">
        <div className="title">{pageData.processData.title}</div>
        <div className="procedure-steps fix-width">
          {pageData.processData.dataItems.map((item, index) => {
            return (<div className="step" key={index}>
              <img className="step-icon" src={item.images[0]} alt="图标" />
              {index < 3 && <img className="step-arrow" src={ArrowIcon} alt="箭头图标" />}
              <div className="step-text">STEP{index + 1}</div>
              <div className="step-desc text-ellipsis">{item.imageFirstDesc}</div>
            </div>)
          })}
        </div>
      </div>}
      {pageData.storeSearchData.showModule && <div className="section warehouse-available fix-width" >
        <div className="anchor" id="storeList"></div>
        <div className="title title-and-search">
          <div className="placeholder"></div>
          <div className="title">{pageData.storeSearchData.title}</div>
          <div className="search-container">

            <div className="city-list-container">
              {!!searchAddress && <div className="search-address text-ellipsis">{searchAddress}</div>}
              <MainMenuItem>
                <span className="city-selected" onClick={()=>{viewAddress()}}>{searchCity}</span>
              </MainMenuItem>
              <Modal width={620} style={{padding:'25px'}} footer={null} visible={showCityList.visible2} onCancel={closeModal2} onOk={closeModal2}>
                {/* <div>
                  {showSearchInput &&<Input placeholder="输入城市名或拼音查询" ref={searchInput} onChange={onInputChanged} onKeyUp={onKeyUp}></Input>}
                  {addressList.length > 0 && <>
                      <div className="address-list">
                        {addressList.map((item) => {
                          return <div key={item.address} className="address-item" onClick={() => { searchByAddress(item.address) }}>{item.address}</div>
                        })}
                      </div>
                      <div className="address-list-mask" onClick={(e) => { hideAddressList(e) }}></div>
                  </>}
                </div> */}
               
                <div className="input-container">
                  {showSearchInput &&
                      <Input placeholder='请输入城市名或拼音查询' 
                        prefix={<SearchOutlined style={{width:'auto',height:'25px',marginTop:'10px'}} />}  
                        ref={searchInput} 
                        onChange={onInputChanged} 
                        onKeyUp={onKeyUp}
                        style={{width:'500px',borderRadius:'50px',marginTop:'20px'}}
                      ></Input>
                  }
                    {addressList.length > 0 && <>
                      <div className="address-list">
                        {addressList.map((item) => {
                          return <div key={item.address} className="address-item" onClick={() => { searchByAddress(item.address) }}>{item.address}</div>
                        })}
                      </div>
                      <div className="address-list-mask" onClick={(e) => { hideAddressList(e) }}></div>
                  </>}
                </div>

                {showCityList && 
                <div className="city-Modal">
                  <span className="all-City">全部城市</span>
                  <div className="city-Table">
                    <Row >
                        {cityList.map((city) => {
                          return (<Col span={6}>
                                    <Button 
                                      key={city} 
                                      className="city" 
                                      style={{width:'100px',marginTop:'15px',fontSize:'15px'}} 
                                      onClick={(e)=>{searchByCity(city)}}
                                    >
                                        {city}
                                    </Button>
                                  </Col>)
                        })}
                    </Row>
                  </div>
                </div>
                } 
              </Modal>
            </div>
          </div>
        </div>
        {showNoResMsg && <div className="no-result"><FormattedMessage id="warehouse.search.noResult.msg" /></div>}
        <div className="warehouse-list">
          {storeList.map((item) => {
            return (
              <div className="warehouse-item" key={item.storeId}>
                <img src={item.thumbnailUrl} alt="仓库图片" />
                <div className="warehouse-name text-ellipsis">{item.storeName}</div>
                <div className="warehouse-address text-ellipsis" onClick={()=>{viewAddressInMap(item)}}>{item.address}</div>
                
                <QrCodeButton data={item} />
              </div>
            )
          })}
        </div>
        {pagination.page < pagination.totalPage && !showNoResMsg && <div className="get-more"><button onClick={getMoreStore}><FormattedMessage id="warehouse.viewMoreBtn.text" /></button></div>}
      </div>}
      <Modal width={800} footer={null} className="map-modal" visible={addressModal.visible} onCancel={closeModal} onOk={closeModal}>
        <AddressInMap latitude={addressModal.latitude} longitude={addressModal.longitude} containerId="storeAddress" />
      </Modal>
    </div>
  );
}

export default injectIntl(WarehouseForRent, { withRef: true });
