/**关于我们页面 - */
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import './index.less';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import CompanyIntro from './company-intro';
import ContactUs from './contact-us';
function AboutUs(props) {
  return (
    <div className="about-us-page">
      <Switch>
            <Route exact path="/aboutUs" component={CompanyIntro} />
            <Route exact path="/aboutUs/contactUs" component={ContactUs} />
      </Switch>
    </div>
  );
}

export default AboutUs;
