/** 加盟合作页面 */
import { useEffect, useState } from 'react';
import { JoinUsWhy, JoinUsHow } from './sections'
import { injectIntl } from 'react-intl';
import { getPageData } from './../../util/utils'
import './index.less';
import { getBannerImage } from '../warehouse-for-rent';
import { getModuleData }  from './../home/sections';

import { SlideBanner } from './../home/sections';
function JoinUs(props) {
  const [pageData, setPageData] = useState({
    bannerData: {},
    joinUsWhoData: { dataItems: []},
    joinUsWhyData: { dataItems: []},
    joinUsOthersData: { dataItems: []},
    joinUsHowData: { dataItems: []},
  })
  useEffect(()=>{
    getPageData({
      pageType: 4,
      lang: props.intl.locale
    }).then((res)=>{
      let { code, data, msg } = res;
      if(code==200){
        // setPageData( data || [] )
        if (data && data.length > 0) {
          let bannerData = getModuleData(data, 1),
            joinUsWhoData = getModuleData(data, 2),
            joinUsWhyData = getModuleData(data, 3),
            joinUsOthersData = getModuleData(data, 4),
            joinUsHowData = getModuleData(data, 5);
          setPageData({
            bannerData: {
              showModule: bannerData.isCheck==1,
              ...bannerData
            },
            joinUsWhoData: {
              showModule: joinUsWhoData.isCheck==1,
              title: joinUsWhoData.title,
              subTitle: joinUsWhoData.cmsModuleInfoVos[0]?joinUsWhoData.cmsModuleInfoVos[0].imageFirstDesc:null,
              containerImage: getBannerImage(joinUsWhoData),
              dataItems: joinUsWhoData.cmsModuleInfoVos
            },
            joinUsWhyData: {
              showModule: joinUsWhyData.isCheck==1,
              title: joinUsWhyData.title,
              dataItems: joinUsWhyData.cmsModuleInfoVos
            },
            joinUsOthersData: {
              showModule: joinUsOthersData.isCheck==1,
              title: joinUsOthersData.title,
              dataItems: joinUsOthersData.cmsModuleInfoVos
            },
            joinUsHowData: {
              title: joinUsHowData.title,
              showModule: joinUsHowData.isCheck==1,
              dataItems: joinUsHowData.cmsModuleInfoVos
            }
          })
        }
      }
    }).catch((res)=>{
      console.log(res);
    })
  },[props.intl.locale])
  return (
    <div className="join-us-page">
      {pageData.bannerData.showModule && <div className="section page-banner">
        <SlideBanner data={pageData.bannerData} />   
      </div>}
      {pageData.joinUsWhoData.showModule && <div className="section join-us-who">
        <div className="title">{pageData.joinUsWhoData.title}</div>
        <div className="sub-title">{pageData.joinUsWhoData.subTitle}</div>
        <div className="fix-width container" style={{ backgroundImage: `url(${pageData.joinUsWhoData.containerImage})` }}>
          {pageData.joinUsWhoData.dataItems.map((item, index)=>{
            if(index==0){
              return null
            }
            return (<div className={`join-us-who-item item${index}`} key={index}>
              <div className={`flex-item`}>
                <div className="img-container">
                  <img src={item.images[0]} />
                </div>
                <div className="text-container">
                  <div className="main-text text-ellipsis">{item.imageFirstDesc}</div>
                  <div className="sub-text text-ellipsis">{item.imageSecondDesc}</div>
                </div>
              </div>
              
            </div>)
          })}
          {/* <img src={imageUrl} /> */}
        </div>
      </div>}
      {pageData.joinUsWhyData.showModule && <JoinUsWhy data={pageData.joinUsWhyData} {...props} />}
      {pageData.joinUsOthersData.showModule && <div className="section join-us-others">
        <div className="title">{pageData.joinUsOthersData.title}</div>
        <div className="fix-width">
          <div className="list">
            {pageData.joinUsOthersData.dataItems.map((item)=>{
              return(
                <div className="list-item" key={item.id}>
                  <img src={item.images[0]} />
                  <div>{item.imageFirstDesc}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>}
      {pageData.joinUsHowData.showModule && <JoinUsHow data={pageData.joinUsHowData}/>}
      
    </div >
  );
}

export default injectIntl(JoinUs, {withRef: true});
