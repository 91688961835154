import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  NavLink,
} from "react-router-dom";
import { IntlProvider, FormattedMessage } from 'react-intl';
// import en from 'react-intl/locale-data/en';
// import zh from 'react-intl/locale-data/zh';
import en_us from './i18n/en_us';
import zh_cn from './i18n/zh_cn';
import Home from './pages/home'
import WarehouseForRent from './pages/warehouse-for-rent';
import BusinessCases from './pages/business-cases';
import JoinUs from './pages/join-us';
import AboutUs from './pages/about-us';
import './App.less';
import { MyQrCode, MainMenuItem } from './components/common';
// addLocaleData([...en, ...zh]); //引入多语言环境的数据
import UserIcon from './images/user-icon.png';
import WechatAppImage from './images/wechat-app.jpg';
import WechatH5Image from './images/wechat-h5.jpg';
import { log } from '@craco/craco/lib/logger';

const LangMessageEnum = {
  'en': en_us,
  'zh': zh_cn
}
const ActiveIndicator = () => {
  return
}

function App(props) {
  const getDefaultLang = () => {
    switch (navigator.language.split('_')[0]) {
      case 'en':
        return 'en';
        break;
      case 'zh':
        return 'zh';
        break;
      default:
        return 'zh';
        break;
    }
  }
  const [currentLang, setCurrentLang] = useState(getDefaultLang());
  const [langListStatus, setLangListStatus] = useState('hide');
  const showLangList = () => {
    setLangListStatus('show')
  }
  const hideLangList = () => {
    setLangListStatus('hide')
  }
  const selectLang = (langType) => {
    setCurrentLang(langType);
    setLangListStatus('hide')
  }
  const navigateToLogin = () => {
    if (window.location.origin == 'https://lecun.demo.hengtiansoft.com' || window.location.origin=='https://lecunuat.demo.hengtiansoft.com') {
      window.open(`${window.location.origin}/platform/#/user/member`)
    } else {
      window.open('https://proxyapi.letstorage.com/platform/#/user/member')
    }
  }
  const navOptions =
    [{
      route: '/storage',
      msgId: 'header.menu.warehouses'
    },
    {
      route: '/businessCases',
      msgId: 'header.menu.businessCases'
    },
    {
      route: '/partners',
      msgId: 'header.menu.joinUs'
    },
      // {
      //   route: '/aboutUs',
      //   msgId: 'header.menu.aboutUs'
      // },
    ]
    const linkto = () => {
      window.open('http://beian.miit.gov.cn')
    }
  return (
    <IntlProvider locale={currentLang} messages={LangMessageEnum[currentLang]}>
      <Router>
        <div className={`app-container app-lang-${currentLang}`}>
          <div className="app-header">
            <div className="header-content fix-width">
              <div className="logo"></div>
              <ul className="navigation">
                <li>
                  {/* <div onClick={()=>{navigate('/'),console.log('aaaapush');}}>111  <FormattedMessage id="header.menu.home" /></div> */}
                  <NavLink to="/" exact strict activeClassName="active"><FormattedMessage id="header.menu.home" /></NavLink>
                  <div className="active-link-indicator"></div>
                </li>
                {navOptions.map((option) => {
                  let { route, msgId } = option;
                  return (
                    <li key={route}>
                      <NavLink to={route} activeClassName="active"><FormattedMessage id={msgId} /></NavLink>
                      <div className="active-link-indicator"></div>
                    </li>
                  )
                })}
                <li className="about-us">
                  {/* <MainMenuItem> */}
                    <NavLink to="/aboutUs" strict activeClassName="active"><FormattedMessage id="header.menu.aboutUs" /></NavLink>
                    <div className="active-link-indicator"></div>
                  {/* </MainMenuItem> */}
                  <ul className={`sub-menu-list about-us-sub-links`}>
                    <li><Link to="/aboutUs"><FormattedMessage id="header.menu.aboutUs.companyIntro" /></Link></li>
                    <li><Link to="/aboutUs/contactUs"><FormattedMessage id="header.menu.aboutUs.contactUs" /></Link></li>
                  </ul>
                </li>
              </ul>
              <div className="switch-lang" onMouseOver={showLangList} onMouseLeave={hideLangList}>
                <MainMenuItem>
                  <div className="current-lang"><FormattedMessage id={`header.langType.${currentLang}`} /></div>
                </MainMenuItem>
                <ul className={`sub-menu-list lang-list ${langListStatus} `}>
                  <li onClick={() => selectLang('zh')}><FormattedMessage id="header.langType.zh" /></li>
                  <li onClick={() => selectLang('en')}><FormattedMessage id="header.langType.en" /></li>
                </ul>
              </div>
              <div className="user-icon" >
                <img src={UserIcon} alt="用户图标" onClick={navigateToLogin} />
              </div>
            </div>
          </div>
          <div className="page-content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/storage" component={WarehouseForRent} />
              <Route exact path="/businessCases" component={BusinessCases} />
              <Route exact path="/partners" component={JoinUs} />
              <Route path="/aboutUs" component={AboutUs} />
              <Redirect to="/" />
            </Switch>
          </div>
          <div className="app-footer">
            <div className="footer-content fix-width">
              <div className="company-phone">
                <div className="phone-number">400-085-7799</div>
                <div className="note-text"><FormattedMessage id="footer.phone.label" /></div>            
              </div>
              <div className="copy-right">
                <FormattedMessage id="footer.copyRight" />
                &nbsp;&nbsp;&nbsp;
                <a onClick={linkto} style={{color: '#fff'}}><FormattedMessage id="footer.copyRightRecord"  /></a>
              </div>
              <div className="wechat-app">
                {/* <MyQrCode size={60} url="www.baidu.com" /> */}
                <img src={WechatAppImage} alt="微信小程序二维码" />
                <div className="note-text"><FormattedMessage id="footer.wechat.app" /></div>
              </div>
              <div className="wechat-h5">
                {/* <MyQrCode size={60} url="www.baidu.com" /> */}
                <img src={WechatH5Image} alt="微信公众号二维码" />
                <div className="note-text"><FormattedMessage id="footer.wechat.h5" /></div>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </IntlProvider >
  );
}

export default App;
