/** 首页 */
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  Banner,
  Statistics,
  IndustryScatterMap,
  ProductService,
  ProfessionalManage,
  MapImage,
  BusinessParterner,
  getModuleData
} from './sections';
import { BottomNavSection } from './../../components/common';
import { getPageData, getComplexPageData } from './../../util/utils';
import './index.less';

function Home(props) {
  const [pageData, setPageData] = useState([])
  const [complexModuleData, setComplexModuleData] = useState([])
  const [bottomNavData, setBottomNavData] = useState({})
  useEffect(()=>{
    getPageData({
      pageType: 1,
      lang: props.intl.locale
    }).then((res)=>{
      let { code, data, msg } = res;
      if(code==200){
        setPageData( data || [] )
        let bottomNav = getModuleData(data, 8);
        setBottomNavData({
          showModule: bottomNav.isCheck==1,
          title: bottomNav.title,
          url: bottomNav.cmsModuleInfoVos?bottomNav.cmsModuleInfoVos[0].imageUrl:null
        })
      }
    }).catch((res)=>{
      console.log(res);
    })
    getComplexPageData({
      pageType: 1,
      lang: props.intl.locale,
      moduleType: 5
    }).then((res)=>{
      let { code, data, msg } = res;
      if(code==200){
        // let complexData = data.filter((item)=>{
        //   return item.moduleType==5
        // })
        setComplexModuleData( data || [] )
      }
    }).catch((res)=>{
      console.log(res);
    })
  },[props.intl.locale])
  const clickBottomNav = ()=>{
    window.open(bottomNavData.url)
  }
  return (
    <div className="home-page">
      <Banner pageData={pageData} />
      <Statistics pageData={pageData} />
      <IndustryScatterMap pageData={pageData} />
      <ProductService pageData={pageData} />
      {complexModuleData.length>0 && complexModuleData[0].isCheck==1 && <ProfessionalManage pageData={complexModuleData} />}
      <MapImage pageData={pageData} />
      <BusinessParterner pageData={pageData} />
      { bottomNavData.showModule && <BottomNavSection>
        <div className="join-us fix-width newTitle " onClick={clickBottomNav}>{bottomNavData.title}</div>
      </BottomNavSection>}
    </div>
  );
}
export default injectIntl(Home, {withRef: true});