import axios from 'axios';
const Service = axios.create({
    baseURL: '/',//配置axios的默认URL
    withCredentials: true, //配置允许跨域携带cookie
    timeout: 10000//配置超时时间
});
Service.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; //标识这是一个ajax请求

Service.interceptors.request.use(function (config) {
    // Do something before request is sent
    //给所有请求的url加上时间戳
    let timeFlag = '?';
    if(config.url.indexOf('?') > -1){
        timeFlag = '&';
    }
    let prefix = '/lecun-official';
    config.url = prefix + config.url + timeFlag + new Date().getTime();   
    // config.headers.common['x-auth-token'] = '14db958e-a83f-447e-b3f2-bf02b62e80f2'; //TODO: TO BE REMOVED
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
Service.interceptors.response.use(function (response) {
    // Do something with response data
    const {data,headers,config} = response;  
    if(data.code===200){
        return data;
    } else if(data.code===401) {
        
    } else {      
        return data;
    }
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});
export default Service;