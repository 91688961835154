const en_us = {
    'header.menu.home': 'HOME',
    'header.menu.warehouses': 'SELF-STORAGE',
    'header.menu.businessCases': 'COMMERCIAL CASES',
    'header.menu.joinUs': 'PARTNERS',
    'header.menu.aboutUs': 'ABOUT US',
    'header.menu.aboutUs.companyIntro': 'COMPANY PROFILE',
    'header.menu.aboutUs.contactUs':'CONTACT US',
    'header.langType.en':'English',
    'header.langType.zh':'简体中文',
    'footer.phone.label':'Customer Service Hotline ',
    'footer.copyRight': 'Copyright Reserved (©) Zhejiang Lecun IoT Technology Co.',
    'footer.copyRightRecord': 'Ltd. Number: 浙ICP备15021429-1', //、浙ICP备2022015038号-1
    'footer.wechat.app':'Wechat Mini Program',
    'footer.wechat.h5':'Wechat Official Account',
    'home.banner.search.placeholder':'choose your nearest storage facility',
    'home.banner.text.main':'when you want to store, find Lecun Storage',
    'home.banner.text.sub':'the first-line brand of Chinese Self-Storage -- the choice of youth and fashion',
    'warehouse.wechat.code.msg':'Scan QR code using WeChat to place an order',
    'warehouse.orderBtn.text':'Order a Self-Storage', 
    'warehouse.orderBtn.textMessage':'Order with Wechat',
    'warehouse.search.noResult.msg':'Sorry, no matching store found.',
    'warehouse.viewMoreBtn.text':'View More',
    'cases.banner.text.main':'cases.banner.text.main',
    'cases.banner.text.sub':'cases.banner.text.sub',
    'joinUs.banner.text.main':'joinUs.banner.text.main',
    'joinUs.how.title':'Contact Info',
    'joinUs.how.phone.title':'Telephone: ',
    'joinUs.how.email.title':'E-mail: ',
    'joinUs.how.name.label':'Name: ',
    'joinUs.how.phone.label':'Phone: ',
    'joinUs.how.email.label':'Email: ',
    'joinUs.how.cooperationType.label':'Intent for Cooperation: ',
    'joinUs.how.message.label':'Message: ',
    'joinUs.how.name.placeholder':'Please fill in your name/company name',
    'joinUs.how.phone.placeholder':'Please fill in your contact number',
    'joinUs.how.email.placeholder':'Please fill in your e-mail',
    'joinUs.how.cooperationType.placeholder':'Please choose your intent for cooperation',
    'joinUs.how.cooperationType.option1':'Partner',
    'joinUs.how.cooperationType.option2':'Distributor',
    'joinUs.how.cooperationType.option3':'Supplier',
    'joinUs.how.cooperationType.option4':'Other',
    'joinUs.how.remark.placeholder': 'Please leave your message',
    'joinUs.how.required.errorMsg':'required',
    'joinUs.how.submit.btn.text':'Submit',
    'companyIntro.vision.title':'Vision · Mission · Concept',
    'contactUs.banner.text':'Contact Us',
    'contactUs.address.domestic.title':'China (HQ) Address',
    'contactUs.address.domestic.detail':'Room 303, West 3rd Floor, Science and Technology Building, East Software Park, No. 90  Wensan Road, Xihu District, Hangzhou City, Zhejiang Province',
    'contactUs.address.overseas.title':'Singapore (HQ) Address',
    'contactUs.address.overseas.detail':'1 SECOND CHIN BEE ROAD SINGAPORE  618768',
    'contactUs.address.viewMap':'To view the map',
    'contactUs.wechatApp.title':'Wechat Mini Program',
    'contactUs.wechatApp.desc':'contactUs wechatApp desc',
    'contactUs.wechatH5.title':'Wechat Official Account',
    'contactUs.wechatH5.desc':'contactUs wechatH5 desc',
    'contactUs.feedback.name.placeholder':'Please fill in your name/company name',
    'contactUs.feedback.phone.placeholder':'Please fill in your contact number',
    'contactUs.feedback.email.placeholder':'Please fill in your e-mail',
    'contactUs.feedback.remark.placeholder': 'Please leave your message',
    'contactUs.feedback.submit.btn.text':'Submit',
    'contactUs.feedback.form.title':'User Feedback',
    'submit.success.msg': 'Success!',
    'submit.error.msg': 'Failed!',

    
}
export default en_us;