const zh_cn = {
    'header.menu.home': '首页',
    'header.menu.warehouses': '自助仓出租',
    'header.menu.businessCases': '商业案例',
    'header.menu.joinUs': '合作伙伴', 
    'header.menu.aboutUs': '关于我们',
    'header.menu.aboutUs.companyIntro':'企业简介',
    'header.menu.aboutUs.contactUs':'联系我们',
    'header.langType.en':'English',
    'header.langType.zh':'简体中文',
    'footer.phone.label':'乐存服务热线',
    'footer.copyRight': '版权所有（©）浙江乐存物联技术有限公司',
    'footer.copyRightRecord': '备案号 ：浙ICP备15021429号-1',
    'footer.wechat.app':'乐存微信小程序',
    'footer.wechat.h5':'乐存微信公众号',
    // 'home.banner.search.placeholder':'输入位置搜索附近门店',
    'home.banner.search.placeholder':'请输入地址搜索附近门店',
    'home.banner.text.main':'存东西 找乐存',
    'home.banner.text.sub':'中国一线自助仓品牌——年轻、时尚的选择',
    'warehouse.wechat.code.msg':'微信扫码下单',
    'warehouse.orderBtn.text':'立即租仓', 
    'warehouse.orderBtn.textMessage':'微信小程序下单',
    'warehouse.search.noResult.msg':'抱歉，没找到匹配门店。',
    'warehouse.viewMoreBtn.text':'查看更多',
    'cases.banner.text.main':'商业案例',
    'cases.banner.text.sub':'为您创造更多商业价值',
    'joinUs.banner.text.main':'合作伙伴招募计划',
    'joinUs.how.title':'合作伙伴预留联系方式',
    'joinUs.how.phone.title':'加盟合作联系电话：',
    'joinUs.how.email.title':'邮箱：',
    'joinUs.how.name.placeholder':'请填写您的姓名/企业名称',
    'joinUs.how.phone.placeholder':'请填写联系电话',
    'joinUs.how.email.placeholder':'请填写邮箱',
    'joinUs.how.cooperationType.placeholder':'请选择合作意向',
    'joinUs.how.cooperationType.option1':'合伙人',
    'joinUs.how.cooperationType.option2':'渠道商',
    'joinUs.how.cooperationType.option3':'供应商',
    'joinUs.how.cooperationType.option4':'其他',
    'joinUs.how.remark.placeholder':'请填写留言信息',
    'joinUs.how.required.errorMsg':'必填',
    'joinUs.how.submit.btn.text':'申请成为服务商',
    // 'companyIntro.banner.text.title':'企业简介',
    // 'companyIntro.banner.text.subTitle':'浙江乐存物联技术有限公司（简称“乐存”）乐存物联技术有限公司（简称“乐存”）乐存物联技术有限公司（简称“乐存”）',
    // 'companyIntro.banner.text.introText':'浙江乐存物联技术有限公司浙江乐存物联技术有限公司浙江乐存物联技术有限公司浙江乐存物联技术有限公司浙江乐存物联技术有限公司浙江乐存物联技术有限公司浙江乐存物联技术有限公司浙江乐存物联技术有限公司浙江乐存物联技术有限公司浙江乐存物联技术有限公司浙江乐存物联技术有限公司浙江乐存物联技术有限公司',
    'companyIntro.vision.title':'愿景 · 使命 · 理念',
    'contactUs.banner.text':'联系我们',
    'contactUs.address.domestic.title':'中国（总部）',
    'contactUs.address.domestic.detail':'浙江省杭州市西湖区文三路90号东部软件园科技大厦三层西303室',
    'contactUs.address.overseas.title':'新加坡（总部）',
    'contactUs.address.overseas.detail':'1 SECOND CHIN BEE ROAD SINGAPORE  618768',
    'contactUs.address.viewMap':'查看地图',
    'contactUs.wechatApp.title':'乐存微信小程序',
    'contactUs.wechatApp.desc':'在线找店租仓，购买配件、代收代寄、搬运服',
    'contactUs.wechatH5.title':'乐存微信公众号',
    'contactUs.wechatH5.desc':'优惠活动、行业资讯',
    'contactUs.feedback.name.placeholder':'请填写您的姓名/企业名称',
    'contactUs.feedback.phone.placeholder':'请填写联系电话',
    'contactUs.feedback.email.placeholder':'请填写邮箱',
    'contactUs.feedback.remark.placeholder':'请填写留言信息',
    'contactUs.feedback.submit.btn.text':'提交',
    'contactUs.feedback.form.title':'客户反馈',
    'submit.success.msg': '提交成功！',
    'submit.error.msg': '提交失败！',
    
    
}

export default zh_cn;