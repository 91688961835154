import Service from './request'
export const LangEnum = {
    'zh': 1,
    'en': 2
}
const LangPageTypeMap = {
    1: {
        1: 29, //首页中文版
        2: 30, //自助仓出租中文版
        3: 31, //商业案例中文版
        4: 32, //合作伙伴中文版
        5: 33, //企业简介中文版
        6: 34, //联系我们中文版
    },
    2: {
        1: 35, //首页英文版
        2: 36, //自助仓出租英文版
        3: 37, //商业案例英文版
        4: 38, //合作伙伴英文版
        5: 39, //企业简介英文版
        6: 40, //联系我们英文版
    }
}
export const getPageData = ({pageType, lang}) => {
    return new Promise(async (resolve, reject)=> {
        try {
            let res = await Service.post('/official/officialModuleList', {
                /** 
                 * fileBusinessTypeEnum - 图片业务类型:
                 * 28-首页中文版, 
                 * 29-自助仓出租中文版, 
                 * 30-商业案例中文版, 
                 * 31-合作伙伴中文版, 
                 * 32-企业简介中文版, 
                 * 33-联系我们中文版, 
                 * 34-首页英文版, 
                 * 35-自助仓出租英文版, 
                 * 36-商业案例英文版, 
                 * 37-合作伙伴英文版, 
                 * 38-企业简介英文版, 
                 * 39-联系我们英文版
                */
                /**
                 * navigationBarType - 导航栏类型 
                 * 1 - 首页 
                 * 2 - 自助仓出租 
                 * 3 - 商业案例 
                 * 4 - 合作伙伴 
                 * 5 - 企业简介 
                 * 6 - 联系我们
                 */
                fileBusinessTypeEnum: LangPageTypeMap[LangEnum[lang]][pageType], 
                isFlag: LangEnum[lang], //中英文标识 中文传1 英文传2
                navigationBarType: pageType, 
                
            });
            if(res.code==200){
                resolve(res)
            } else {
                reject(res)
            }
        } catch {
            console.log('error')
            reject()
        }
        
      })  
}

export const getComplexPageData = ({pageType, lang, moduleType}) => {
    return new Promise(async (resolve, reject)=> {
        try {
            let res = await Service.post('/official/officialComplexList', {
                fileBusinessTypeEnum: LangPageTypeMap[LangEnum[lang]][pageType], 
                isFlag: LangEnum[lang], //中英文标识 中文传1 英文传2
                navigationBarType: pageType, 
                moduleType
            });
            if(res.code==200){
                resolve(res)
            } else {
                reject(res)
            }
        } catch {
            console.log('error')
            reject()
        }
        
      })  
}



