/** 商业案例页面 */
import { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { getPageData, getComplexPageData } from './../../util/utils'
import { BottomNavSection } from './../../components/common';
import { getBannerImage } from '../warehouse-for-rent';
import { getModuleData } from './../home/sections';
import { SlideBanner } from './../home/sections';
import './index.less';

function BusinessCases(props) {
  const [pageData, setPageData] = useState({
    bannerData: {},
    businessCases: { dataItems: [] },
    bottomNav: {}
  })
  const [complexModuleData,setComplexModuleData] = useState([])
  useEffect(() => {
    getPageData({
      pageType: 3,
      lang: props.intl.locale
    }).then((res) => {
      let { code, data, msg } = res;
      if (code == 200) {
        // setPageData( data || [] )
        if (data && data.length > 0) {
          let bannerData = getModuleData(data, 1),
            // businessCases = getModuleData(data, 2), //案例数据由complex接口返回
            bottomNav = getModuleData(data, 3);
          setPageData({
            bannerData: {
              showModule: bannerData.isCheck==1,
              ...bannerData
            },
            bottomNav: {
              showModule: bottomNav.isCheck==1,
              title: bottomNav.title,
              url: bottomNav.cmsModuleInfoVos ? bottomNav.cmsModuleInfoVos[0].imageUrl : null
            }
          })
        }
      }
    }).catch((res) => {
      console.log(res);
    })
    getComplexPageData({
      pageType: 3,
      lang: props.intl.locale,
      moduleType: 2
    }).then((res)=>{
      let { code, data, msg } = res;
      if(code==200){
        setComplexModuleData(parseComplexData(data))
      }
    }).catch((res)=>{
      console.log(res);
    })
  }, [props.intl.locale])
  const clickBottomNav = () => {
    if (pageData.bottomNav.url) {
      window.open(pageData.bottomNav.url)
    }
  }
  const parseComplexData = (data)=>{
    if(!data){
      return []
    }
    //根据相同的title将数据组合到一起
    let res = [];
    data.forEach((item)=>{
      let index = res.findIndex((resItem)=>{
        return resItem.title == item.title
      });

      if(index==-1){
        res.push({
          isCheck: item.isCheck,
          title: item.title,
          imageSort: item.imageSort,
          dataItems: [parseComplexDataItem(item)]
        })
      } else {
        res[index].dataItems.push(parseComplexDataItem(item))
      }
    });
    return sortComplexData(res);
  }
  const sortComplexData = (source)=>{
    let res = [];
    source.forEach((item, index)=>{
      if(index==0){
        res[index] = item;
      } else {
        let positionIndex = res.findIndex((resItem)=>{
          return resItem.imageSort > item.imageSort
        });
        if(positionIndex==-1){
          res.push(item)
        } else {
          res.splice(positionIndex,0,item)
        }
      }
    })
    return res;
  }
  const parseComplexDataItem = (dataItem)=>{
    let {images, complexImagesDtos, id} = dataItem;
    let complexImagesItem = {};
    if(complexImagesDtos && complexImagesDtos.length>0){
      complexImagesItem = complexImagesDtos[0]
    }
    return {
      id,
      mainImage: images?images[0]:null,
      mainText: dataItem.imageFirstDesc,
      logoImage: complexImagesItem.images?complexImagesItem.images[0]:null,
    }
  }
  return (
    <div className="business-cases-page">
      {pageData.bannerData.showModule && <div className="section page-banner">
        <SlideBanner data={pageData.bannerData} />   
      </div>}

      {complexModuleData.length>0 && complexModuleData[0].isCheck==1 && complexModuleData.map((item,index) => {
        return (
          <div className="section cases-by-industry fix-width" key={index}>
            <div className="title">{item.title}</div>
            <div className="case-list">
              {item.dataItems && item.dataItems.map((subItem,index) => {
                return (
                  <div className="case-item" key={index}>
                    <img className="case-image" src={subItem.mainImage} alt="案例图片" />
                    <img className="logo-image" src={subItem.logoImage} alt="合作企业logo" />
                    <div className="case-name text-ellipsis">{subItem.mainText}</div>
                    <div className="highlight-segment"></div>
                  </div>
                )
              })}
            </div>
            <div className="break-line">
              {/* <div className="highlight-segment"></div> */}
            </div>
          </div>
        )
      })}
       {pageData.bottomNav.showModule && <BottomNavSection>
        <div className="join-us fix-width newTitle" onClick={clickBottomNav}>{pageData.bottomNav.title}</div>
      </BottomNavSection>}
    </div>
  );
}

export default injectIntl(BusinessCases, { withRef: true });
