/** 首页 */
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Input } from 'antd';
import QRCode from 'qrcode.react';
import './index.less';

//footer上方黄色背景导航区块
export const BottomNavSection = (props) => {
    return (
        <div className="common-component bottom-nav-section">
            {props.children}
        </div>
    )
}

//二维码
export const MyQrCode = (props) => {
    const {
        url, //生成二维码的链接
        size = 200, //二维码的宽高尺寸
        color = '#000000' //二维码的颜色
    } = props;
    return (
        <div className="common-component erweima">
            <QRCode
                value={url}
                size={size}
                fgColor={color}
            />
        </div>

    )
}

export const MainMenuItem = (props) => {
    const onClick = () => {
        if (props.onClick && typeof props.onClick == 'function') {
            props.onClick()
        }
    }
    return (
        <div className="common-component main-menu-item" onClick={onClick}>
            <span className="arrow-down"></span>
            {props.children}
        </div>
    )
}


export const NavigationArrow = (props) => {
    const showPrev = () => {
        props.showPrev()
    }
    const showNext = () => {
        props.showNext()
    }
    return (
        <div className="common-component prev-next-arrows">
            <div className="arrow pre-arrow" onClick={showPrev}></div>
            <div className="arrow next-arrow" onClick={showNext}></div>
        </div>
    )

}


{/* <Modal width={800} footer={null} className="map-modal" visible={modalInfo.visible} onCancel={closeModal} onOk={closeModal}>
        <AddressInMap latitude={1.33005} longitude={103.714702} containerId="overseasMap" />
      </Modal> */}
let mapInstance, layerInstance;
export const AddressInMap = (props) => {
    useEffect(() => {
        initMap()
    }, [props.latitude, props.longitude])
    const TMap = window.TMap;
    const initMap = () => {
        const { latitude, longitude, containerId } = props;
        let mapContainerDom = document.getElementById(containerId);
        // mapContainerDom.innerHTML = null;
        let center = new TMap.LatLng(latitude, longitude);//设置中心点坐标
        if (mapInstance) {
            mapInstance.setCenter(center)
        } else {
            mapInstance = new TMap.Map(mapContainerDom, { center: center }); //初始化地图
        }

        if (layerInstance) {
            layerInstance.setGeometries([{
                "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                "styleId": 'myStyle',  //指定样式id
                "position": new TMap.LatLng(latitude, longitude),  //点标记坐标位置
                "properties": {//自定义属性
                    "title": "marker1"
                }
            }])
        } else {
            //创建并初始化MultiMarker
            layerInstance = new TMap.MultiMarker({
                map: mapInstance,  //指定地图容器
                //样式定义
                styles: { //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
                    "myStyle": new TMap.MarkerStyle({
                        "width": 25,  // 点标记样式宽度（像素）
                        "height": 35, // 点标记样式高度（像素）
                        // "src": AnchorImage,  //图片路径
                        //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                        "anchor": { x: 16, y: 32 }
                    })
                },
                //点标记数据数组
                geometries: [{
                    "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                    "styleId": 'myStyle',  //指定样式id
                    "position": new TMap.LatLng(latitude, longitude),  //点标记坐标位置
                    "properties": {//自定义属性
                        "title": "marker1"
                    }
                }]
            });
        }

    }
    return (
        <div id={props.containerId}></div>
    )

}