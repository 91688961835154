/** 首页 */
import { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Input, Select, message,Row,Col } from 'antd';
import './index.less';
import JoinUsFormLeftImg from './../../images/join-us-form-left.png';
import Service from '../../util/request';
import { NavigationArrow } from './../../components/common'
import SixHighlightsBg from './../../images/six-highlights-bg.png'
const { Option } = Select;

export const JoinUsWhy = (props) => {
    const [data, setData] = useState(props.data || { dataItems: [] })
    const [itemsInDisplay, setItemsInDisplay] = useState([]);
    const [activeItemIndex, setActiveItemIndex] = useState(0)
    useEffect(() => {
        if (props.data) {
            setData(props.data)
            let items = getItemsInDisplay(0, props.data.dataItems);
            setItemsInDisplay(items)
        }
    }, [props.data])
    const getItemsInDisplay = (activeIndex, sourceData) => {
        let source = [].concat(sourceData),
            total = source.length;
        let res = [];
        if (total == 0) {
            return []
        }
        let prevIndex, nextIndex, prevItem, nextItem;
        if (activeIndex == 0) {
            prevIndex = total - 1;
            nextIndex = activeIndex + 1
        } else if (activeIndex == (total - 1)) {
            prevIndex = activeIndex - 1;
            nextIndex = 0;
        } else {
            prevIndex = activeIndex - 1;
            nextIndex = activeIndex + 1
        }
        prevItem = source[prevIndex];
        nextItem = source[nextIndex];
        let activeItem = source[activeIndex];
        if(prevItem){
            prevItem = {
                ...prevItem,
                imageSecondDesc: parseDescToArray(prevItem.imageSecondDesc)
            }
        } else {
            prevItem = {}
        }
        if(nextItem){
            nextItem = {
                ...nextItem,
                imageSecondDesc: parseDescToArray(nextItem.imageSecondDesc)
            }
        } else {
            nextItem = {}
        } 
        if(activeItem){
            activeItem = {
                ...activeItem,
                imageSecondDesc: parseDescToArray(activeItem.imageSecondDesc)
            }
        } else {
            activeItem = {}
        }
        return [prevItem, activeItem, nextItem]
    }
    const parseDescToArray = (str)=>{
        while(new RegExp('；').test(str)){
            str = str.replace('；',';');
        }
        if(/\;$/.test(str)){
            str = str.substr(0,str.length-1)
        }
        return str.split(';')
    }
    const clickHighlightCategory = (index)=>{
        setActiveItemIndex(index)
        let items = getItemsInDisplay(index, data.dataItems);
        setItemsInDisplay(items);
    }
    const showPrev = () => {
        let lastIndex = data.dataItems.length - 1; 
        let nextActiveIndex; 
        if(activeItemIndex==0){
            nextActiveIndex = lastIndex;
        } else {
            nextActiveIndex = activeItemIndex - 1; 
        }
        clickHighlightCategory(nextActiveIndex)
    }
    const showNext = () => {
        let lastIndex = data.dataItems.length - 1; 
        let nextActiveIndex; 
        if(activeItemIndex==lastIndex){
            nextActiveIndex = 0;
        } else {
            nextActiveIndex = activeItemIndex + 1; 
        }
        clickHighlightCategory(nextActiveIndex)
    }
    // let itemsInDisplay = getItemsInDisplay();
    let activeItem = itemsInDisplay.length > 0 ? itemsInDisplay[1] : {images:[]};
    return (
        <div className="section join-us-why" style={{ backgroundImage: `url(${SixHighlightsBg})` }}>
            <div className="mask">
                <div className="title section-title">{data.title}</div>
                <div className="fix-width">
                    <div className="highlights-list">
                        {data.dataItems.map((item, index) => {
                            return (
                                <div key={index} className="highlight-category" onMouseOver={()=>{clickHighlightCategory(index)}}>
                                    <div className="text-ellipsis" style={{paddingLeft:5,paddingRight:5}}>{item.imageFirstDesc}</div>
                                    {index == activeItemIndex && <div className="highlight-category-active"></div>}
                                </div>
                            )
                        })}
                    </div>
                    <div className="highlight-category-details">
                        <div className="detail-list">
                            {itemsInDisplay.map((item, index) => {
                                if (index == 1) { return null }
                                return (
                                    <div className="detail-item" key={index}>
                                        <div className="detail-info">
                                            <div className="detail-info-title text-ellipsis2" style={{ backgroundImage: `url(${item.images[0]})` }}>{item.imageFirstDesc}</div>
                                            <div className="detail-info-content-list">
                                                {item.imageSecondDesc && item.imageSecondDesc.map((value,index) => {
                                                    return <div className="content-item" key={index}>{value}</div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="detail-item detail-item-active">
                                <div className="detail-info">
                                    <div className="detail-info-title text-ellipsis2" style={{ backgroundImage: `url(${activeItem.images[0]})` }}>{activeItem.imageFirstDesc}</div>
                                    <div className="detail-info-content-list">
                                        {activeItem.imageSecondDesc && activeItem.imageSecondDesc.map((value,index) => {
                                            return <div className="content-item" key={index}>{value}</div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NavigationArrow showPrev={showPrev} showNext={showNext} />
                    </div>
                </div>
            </div>

        </div>
    )
}

// const JoinUsFormLeftImg = require('./../../images/join-us-form-left.png');
const JoinUsHowComponent = (props) => {
    const [form] = Form.useForm();
    let submitting = false;
    useEffect(() => {
        form.resetFields()
    }, [props.intl.locale])
    const onFinish = (values) => {   
        if (submitting == true) {
            return;
        }
        submitting = true;
        let params = {
            ...values,
            // createId: -1, //创建人,默认为 -1
            // registrationType: 0, //	登记类型,默认为0-官网咨询
            // revisitTime: '2022-10-26', //new Date(), //回访时间，默认为创建时间
        }
        Service.post('/official/addPartnerIntentionCooperation', params).then((res) => {
            submitting = false;
            let { code, data, msg } = res;
            if (code == 200) {
                message.success(props.intl.messages['submit.success.msg']);
                form.resetFields();
            } else {
                message.error(props.intl.messages['submit.error.msg']);
            }
        }).catch(() => {
            submitting = false;
            message.error(props.intl.messages['submit.error.msg']);
        })
    }
    return (
        <div className="section join-us-how">
            <div className="title">
                {props.data.title} 
                {/* <FormattedMessage id="joinUs.how.title" /> */}
            </div>
            <div className="sub-title"><FormattedMessage id="joinUs.how.phone.title" />13456783123 &nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="joinUs.how.email.title" />yj@plstorage.com</div>
            <div className="fix-width join-us-form-container">
                <div className="img-container">
                    <img src={JoinUsFormLeftImg} />
                </div>
                <div className="form-container">
                    <Form
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                    > 
                        <Row>
                            <Col span={1}>
                            <span style={{ color: 'red'}}>*</span>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: props.intl.messages['joinUs.how.required.errorMsg'] }]}
                                >
                                    <Input style={{width:'470px'}} placeholder={props.intl.messages['joinUs.how.name.placeholder']} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={1}>
                            <span style={{ color: 'red'}}>*</span>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="phone"
                                    rules={[
                                        { required: true, message: props.intl.messages['joinUs.how.required.errorMsg'] },
                                        // { pattern: /^1\d{10}$/, message: '请填写合法11位的手机号！'}
                                    ]}
                                >
                                    <Input style={{width:'470px'}} placeholder={props.intl.messages['joinUs.how.phone.placeholder']} />
                                </Form.Item>
                            </Col>
                        </Row>
                    
                            <Form.Item
                                name="email"
                                style={{marginLeft:'20px'}}
                            >
                                <Input style={{width:'470px'}} placeholder={props.intl.messages['joinUs.how.email.placeholder']} />
                            </Form.Item>
                        <Row>
                            <Col span={1}>
                            <span style={{ color: 'red'}}>*</span>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="cooperationType"
                                    rules={[{ required: true, message: props.intl.messages['joinUs.how.required.errorMsg'] }]}
                                >
                                    <Select style={{width:'460px'}}  placeholder={props.intl.messages['joinUs.how.cooperationType.placeholder']}>
                                        <Option value={0}><FormattedMessage id="joinUs.how.cooperationType.option1" /></Option>
                                        <Option value={1}><FormattedMessage id="joinUs.how.cooperationType.option2" /></Option>
                                        <Option value={2}><FormattedMessage id="joinUs.how.cooperationType.option3" /></Option>
                                        {/* <Option value={3}>服务商</Option> */}
                                        <Option value={4}><FormattedMessage id="joinUs.how.cooperationType.option4" /></Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name="remark"
                            style={{marginLeft:'20px'}}
                        >
                            <Input.TextArea style={{width:'470px'}} placeholder={props.intl.messages['joinUs.how.remark.placeholder']} autoSize={{minRows: 4, maxRows: 4}}/>
                        </Form.Item>
                        <Form.Item>
                            <Button className="submit-button" htmlType="submit"><FormattedMessage id="joinUs.how.submit.btn.text" /></Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>

        </div>
    )
}

export const JoinUsHow = injectIntl(JoinUsHowComponent, { withRef: true });