/**关于我们页面 - 联系我们*/
import React, { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { MyQrCode } from '../../../components/common';
import { Button, Form, Input, message, Modal,Row,Col } from 'antd';
import './index.less';
import JoinUsFormLeftImg from './../../../images/join-us-form-left.png';
import { getPageData, LangEnum } from './../../../util/utils'
import Service from '../../../util/request';
import { getBannerImage } from '../../warehouse-for-rent';
import { getModuleData } from '../../home/sections';
import WechatAppImage from './../../../images/wechat-app.jpg';
import WechatH5Image from './../../../images/wechat-h5.jpg';
import { SlideBanner } from './../../home/sections';

const AddressInMap = (props) => {
  useEffect(() => {
    initMap()
  }, [])
  const TMap = window.TMap;
  const initMap = () => {
    const { latitude, longitude, containerId } = props;
    let mapContainerDom = document.getElementById(containerId);
    mapContainerDom.innerHTML = null;
    let center = new TMap.LatLng(latitude, longitude);//设置中心点坐标
    let map = new TMap.Map(mapContainerDom, { center: center }); //初始化地图
    //创建并初始化MultiMarker
    new TMap.MultiMarker({
      map: map,  //指定地图容器
      //样式定义
      styles: { //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
        "myStyle": new TMap.MarkerStyle({
          "width": 25,  // 点标记样式宽度（像素）
          "height": 35, // 点标记样式高度（像素）
          // "src": AnchorImage,  //图片路径
          //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
          "anchor": { x: 16, y: 32 }
        })
      },
      //点标记数据数组
      geometries: [{
        "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
        "styleId": 'myStyle',  //指定样式id
        "position": new TMap.LatLng(latitude, longitude),  //点标记坐标位置
        "properties": {//自定义属性
          "title": "marker1"
        }
      }]
    });
  }
  return (
    <div id={props.containerId}></div>
  )

}

function ContactUs(props) {
  const [pageData, setPageData] = useState({
    bannerData: {},
    contactInfoData: { dataItems: []},
    feedback:{},
    hqAddress: {}
  })
  const [domesticMapModalVisible, setDomesticMapModalVisible] = useState(false)
  const [overseasMapModalVisible, setOverseasMapModalVisible] = useState(false)
  const [form] = Form.useForm();
  let submitting = false;
  useEffect(() => {
    getPageData({
      pageType: 6,
      lang: props.intl.locale
    }).then((res) => {
      let { code, data, msg } = res;
      if (code == 200) {
        if (data && data.length > 0) {
          let bannerData = getModuleData(data, 1),
            contactInfoData = getModuleData(data, 2),
            feedback = getModuleData(data, 3),
            hqAddress = getModuleData(data, 4);
          setPageData({
            bannerData: {
              showModule: bannerData.isCheck == 1,
              ...bannerData
            },
            contactInfoData: {
              showModule: contactInfoData.isCheck == 1,
              title: contactInfoData.title,
              dataItems: contactInfoData.cmsModuleInfoVos || []
            },
            feedback: {
              showModule: feedback.isCheck == 1,
            },
            hqAddress: {
              showModule: hqAddress.isCheck == 1,
            },
          })
        }
      }
    }).catch((res) => {
      console.log(res);
    })
    form.resetFields()
  }, [props.intl.locale])
  const onFinish = (values) => {
    if (submitting == true) { //阻止重复提交
      return;
    }
    submitting = true;
    let params = {
      ...values,
      intentionType: 'u', //登记类型 官网默认: u官网客户反馈
      memberType: 'p'	//用户类型 默认: p个人
    }
    Service.post('/official/addMemberFeedbackMsg', params).then((res) => {
      submitting = false;
      let { code, data, msg } = res;
      if (code == 200) {
        form.resetFields();
        message.success(props.intl.messages['submit.success.msg']);
      } else {
        message.error(props.intl.messages['submit.error.msg']);
      }
    }).catch(() => {
      submitting = false;
      message.error(props.intl.messages['submit.error.msg']);
    })
  }
  const showMap = (type) => {
    if (type == 'domestic') {
      setDomesticMapModalVisible(true)
    } else {
      setOverseasMapModalVisible(true)
    }
  }
  const closeDomesticMapModal = () => {
    setDomesticMapModalVisible(false)
  }
  const closeOverseasMapModal = () => {
    setOverseasMapModalVisible(false)
  }
  let clientServiceInfo = pageData.contactInfoData.dataItems[0] || {images:[]},
  businessCooperationInfo = pageData.contactInfoData.dataItems[1] || {images:[]},
  marketCooperationInfo = pageData.contactInfoData.dataItems[2] || {images:[]},
  otherCooperationInfo = pageData.contactInfoData.dataItems[3] || {images:[]};
  return (
    <div className="contact-us">
      {pageData.bannerData.showModule && <div className="section page-banner">
        <SlideBanner data={pageData.bannerData} />   
        {/* <div className="fix-width banner-text">
          <FormattedMessage id="contactUs.banner.text" />
        </div> */}
      </div>}
      {pageData.contactInfoData.showModule && <div className="section contact-info">
        <div className="fix-width contact-info-list">
          <div className="contact-info-item wechat-h5">
            <div className="title"><FormattedMessage id="contactUs.wechatH5.title" /></div>
            <div className="qrcode-container">
              {/* <MyQrCode url="https://www.baidu.com" size={160} /> */}
              <img src={WechatH5Image} alt="qrCode" />
            </div>
            {/* <div className="note-text"><FormattedMessage id="contactUs.wechatH5.desc" /></div> */}
          </div>
          <div className="contact-info-item wechat-app">
            <div className="title"><FormattedMessage id="contactUs.wechatApp.title" /></div>
            <div className="qrcode-container">
              {/* <MyQrCode url="https://www.baidu.com" size={160} /> */}
              <img src={WechatAppImage} alt="qrCode" />
            </div>
            {/* <div className="note-text"><FormattedMessage id="contactUs.wechatApp.desc" /></div> */}
          </div>
          <div className="contact-info-item other-contact-info">
            <ul>
              {clientServiceInfo && <li className="client-service" >
                <div className="title" style={{backgroundImage: `url(${clientServiceInfo.images[0]})`}}>{clientServiceInfo.imageFirstDesc}</div>
                <div className="info">{clientServiceInfo.imageSecondDesc}</div>
                <div className="info">{clientServiceInfo.imageThirdDesc}</div>
              </li>}
              {businessCooperationInfo && <li className="business-cooperation" >
                <div className="title" style={{backgroundImage: `url(${businessCooperationInfo.images[0]})`}}>{businessCooperationInfo.imageFirstDesc}</div>
                <div className="info">{businessCooperationInfo.imageSecondDesc}</div>
              </li>}
              {marketCooperationInfo && <li className="market-cooperation" >
                <div className="title" style={{backgroundImage: `url(${marketCooperationInfo.images[0]})`}}>{marketCooperationInfo.imageFirstDesc}</div>
                <div className="info">{marketCooperationInfo.imageSecondDesc}</div>
              </li>}
              {otherCooperationInfo && <li className="other-cooperation" >
                <div className="title" style={{backgroundImage: `url(${otherCooperationInfo.images[0]})`}}>{otherCooperationInfo.imageFirstDesc}</div>
                <div className="info">{otherCooperationInfo.imageSecondDesc}</div>
              </li>}
            </ul>
          </div>
        </div>
      </div>}
      {pageData.feedback.showModule && <div className="section user-feedback">
        <div className="fix-width title"><FormattedMessage id="contactUs.feedback.form.title" /></div>
        <div className="fix-width user-feedback-form-container">
          <div className="img-container">
            <img src={JoinUsFormLeftImg} />
          </div>
          <div className="form-container">
            <Form
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >      
            <Row>
              <Col span={1}>
                <span style={{ color: 'red'}}>*</span>
              </Col>
              <Col span={8}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: props.intl.messages['contactUs.feedback.name.placeholder'] }]}
              >
                <Input style={{width:'450px'}} placeholder={props.intl.messages['contactUs.feedback.name.placeholder']} />
              </Form.Item>
              </Col>
            </Row>
              <Row>
              <Col span={1}>
                <span style={{ color: 'red'}}>*</span>
              </Col>
              <Col span={8}>
              <Form.Item
                name="phone"
                rules={[{ required: true, message: props.intl.messages['contactUs.feedback.phone.placeholder'] }]}
              >
                <Input style={{width:'450px'}} placeholder={props.intl.messages['contactUs.feedback.phone.placeholder']} />
              </Form.Item>
              </Col>
              </Row>
              <Row>
              <Form.Item
                name="email"
                style={{marginLeft:'20px'}}
              >
                <Input  style={{width:'450px'}} placeholder={props.intl.messages['contactUs.feedback.email.placeholder']} />
              </Form.Item>
              {/* <span style={{ color: 'red',display:'inline-block'}}>*</span> */}
              <Row>
              <Col span={1}>
                <span style={{ color: 'red'}}>*</span>
              </Col>
              <Col span={23}>
              <Form.Item
                name="remark"
                rules={[{ required: true, message: props.intl.messages['contactUs.feedback.remark.placeholder']}]}
              >
                <Input.TextArea style={{width:'460px'}} placeholder={props.intl.messages['contactUs.feedback.remark.placeholder']} autoSize={{minRows: 4, maxRows: 4}}/>
              </Form.Item>
              </Col>
              </Row>
                <Form.Item>
                  <Button className="submit-button" htmlType="submit">{props.intl.messages['contactUs.feedback.submit.btn.text']}</Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
        </div>
      </div>}
      {pageData.hqAddress.showModule && <div className="company-address">
        <div className="fix-width title"><FormattedMessage id="contactUs.banner.text" /></div>
        <div className="address-list">
          <div className="address-item China">
            <div className="address-title"><FormattedMessage id="contactUs.address.domestic.title" /></div>
            <div className="address-detail"><FormattedMessage id="contactUs.address.domestic.detail" /></div>
            <Button type="link" className="view-map" onClick={() => { showMap('domestic') }}><FormattedMessage id="contactUs.address.viewMap" />&rarr;</Button>
          </div>
          <div className="address-item Singapore">
            <div className="address-title"><FormattedMessage id="contactUs.address.overseas.title" /></div>
            <div className="address-detail"><FormattedMessage id="contactUs.address.overseas.detail" /></div>
            <Button type="link" className="view-map" onClick={() => { showMap('overseas') }}><FormattedMessage id="contactUs.address.viewMap" />&rarr;</Button>
          </div>
        </div>
      </div>}
        {/* <div className="fix-width title"><FormattedMessage id="contactUs.banner.text" /></div>
        <div className="fix-width addressList">
          <div className="address-item China">
            <div className="address-title"><FormattedMessage id="contactUs.address.domestic.title" /></div>
            <div className="address-detail"><FormattedMessage id="contactUs.address.domestic.detail" /></div>
            <Button type="link" className="view-map" onClick={() => { showMap('domestic') }}><FormattedMessage id="contactUs.address.viewMap" />&rarr;</Button>
          </div>
          <div className="address-item Singapore">
            <div className="address-title"><FormattedMessage id="contactUs.address.overseas.title" /></div>
            <div className="address-detail"><FormattedMessage id="contactUs.address.overseas.detail" /></div>
            <Button type="link" className="view-map" onClick={() => { showMap('overseas') }}><FormattedMessage id="contactUs.address.viewMap" />&rarr;</Button>
          </div>
        </div> */}
      <Modal width={800} footer={null} className="map-modal" visible={domesticMapModalVisible} onCancel={closeDomesticMapModal} onOk={closeDomesticMapModal}>
        <AddressInMap key="domestic" latitude={30.279074} longitude={120.145814} containerId="domesticMap" />
      </Modal>
      <Modal width={800} footer={null} className="map-modal" visible={overseasMapModalVisible} onCancel={closeOverseasMapModal} onOk={closeOverseasMapModal}>
        <AddressInMap key="overseas" latitude={1.33005} longitude={103.714702} containerId="overseasMap" />
      </Modal>
    </div>
  );
}

export default injectIntl(ContactUs, { withRef: true });
