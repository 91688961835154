/** 首页 */
import React, { useDebugValue, useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Input } from 'antd';
import Slide from './../../components/slide';
import Service from './../../util/request';
import './index.less';
import SearchIcon from './../../images/search-icon.png'
import { withRouter } from "react-router-dom";
import { NavigationArrow } from './../../components/common'

const OVER = 1.3

export const getModuleData = (pageData, moduleType) => {
    let res = {};
    if (pageData) {
        res = pageData.find((item) => {
            return Number(item.moduleType) == moduleType
        }) || {};
    }
    return res;
}

const BannerSlideTemplate = (props) => {
    let { data } = props;
    const clickBanner = () => {
        if (data.url) {
            window.open(data.url)
        }
    }
    return (<div className={data.url ? 'slide-item clickable' : 'slide-item'} style={{ backgroundImage: `url(${data.imageUrl})` }} onClick={clickBanner}></div>)
}
export const SlideBanner = (props) => {
    const [showModule, setShowModule] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        let moduleData = props.data;
        let { cmsModuleInfoVos = [] } = moduleData;
        let bannerData = [];
        if (cmsModuleInfoVos.length > 0) {
            bannerData = cmsModuleInfoVos.map((item = {}) => {
                let { images, imageUrl, imageSort } = item;
                return {
                    imageSort,
                    imageUrl: images ? images[0] : '',
                    url: imageUrl,
                }
            })
        }
        let sortedBannerData = sortBannerData(bannerData);
        setData(sortedBannerData);
    }, [props.data])
    const sortBannerData = (source) => {
        let res = [];
        source.forEach((item, index) => {
            if (index == 0) {
                res[index] = item;
            } else {
                let positionIndex = res.findIndex((resItem) => {
                    return resItem.imageSort > item.imageSort
                });
                if (positionIndex == -1) {
                    res.push(item)
                } else {
                    res.splice(positionIndex, 0, item)
                }
            }
        })
        return res;
    }
    return (
        <div className="banner-slide-container">
            <Slide
                slideTemplate={BannerSlideTemplate}
                data={data} />
        </div>
    )
}
const BannerComponent = (props) => {
    const [showModule, setShowModule] = useState(false);
    const [data, setData] = useState({});
    const [addresses, setAddresses] = useState([]);
    const searchInput = React.createRef();
    useEffect(() => {
        let moduleData = getModuleData(props.pageData, 1);
        setShowModule(moduleData.isCheck == 1)
        setData(moduleData);
    }, [props.pageData])
    const onInputChanged = (e) => {
        let value = e.target.value;
        if (value !== null) {
            Service.post('/official/store-map', { keyword: value }).then((res) => {
                console.log(res);
                let { code, data } = res;
                if (code == 200) {
                    setAddresses(data);
                }
            })
        }
    }
    const onKeyUp = (e) => {
        console.log(e)
        if (e.keyCode == 13) { //enter键
            searchStore({ address: e.currentTarget.value })
        }
    }
    const searchStore = (item) => {
        props.history.push(`/storage#storeList`, { address: item.address })
    }
    const clickSearchIcon = () => {
        let searchText = searchInput.current.state.value || '';
        props.history.push(`/storage#storeList`, { address: searchText })
    }
    let searchInputPlaceholder = props.intl.formatMessage({ id: 'home.banner.search.placeholder' });
    if (!showModule) {
        return null;
    }
    return (
        <div className="home-section banner">
            <SlideBanner data={data} />
            <div className="banner-text">
                {/* <div className="main-text"><FormattedMessage id="home.banner.text.main" /></div>
                <div className="sub-text"><FormattedMessage id="home.banner.text.sub" /></div> */}
                <div className="input-container">
                    <Input placeholder={searchInputPlaceholder} ref={searchInput} onChange={onInputChanged} onKeyUp={onKeyUp} />
                    <img src={SearchIcon} alt="搜索图标" className="search-icon" onClick={clickSearchIcon} />

                    {addresses.length > 0 && <div className="address-list">
                        {addresses.map((item, index) => {
                            return <div key={index} className="address-item" onClick={() => { searchStore(item) }}>{item.address}</div>
                        })}
                    </div>}
                </div>
            </div>
        </div>
    )
}
export const Banner = injectIntl(withRouter(BannerComponent), { withRef: true })

export const Statistics = (props) => {
    const [showModule, setShowModule] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        let moduleData = getModuleData(props.pageData, 2);
        setShowModule(moduleData.isCheck == 1)
        let { cmsModuleInfoVos = [] } = moduleData;
        setData(cmsModuleInfoVos);
    }, [props.pageData])
    if (!showModule) {
        return null;
    }
    return (
        <div className="home-section statistics">
            <div className="statistic-list fix-width">
                {data.map((item,index) => {
                    return <div className="statistic-item" key={index}>
                        <div className="value">
                            <div className="value-num">{item.imageSecondDesc}</div>
                            <div className="value-unit">{item.imageThirdDesc}</div>
                        </div>
                        <div className="label">{item.imageFirstDesc}</div>
                    </div>
                })}
            </div>
        </div>
    )
}
let myChart; 
export const IndustryScatterMap = (props) => {
    const [showModule, setShowModule] = useState(true);
    const [data, setData] = useState({});
    const [activeItem, setActiveItem] = useState({});
    // const [myChart, setMyChart] = useState();
    let mapRef = React.createRef();
    const activeRadius = 220;
    const activeFontSize = 36;
    const activeColor = '#014c95'; 
    // const activeColor = '#a15c06'; 
    // let myChart;
    useEffect(() => {
        let moduleData = getModuleData(props.pageData, 3);
        setData(moduleData);
        if (moduleData.isCheck == 0) {
            setShowModule(false)
        }
        let { cmsModuleInfoVos = [] } = moduleData;
        if (cmsModuleInfoVos && cmsModuleInfoVos.length > 0) {
            initChart(moduleData);
        }
    }, [props.pageData])
    const generateRandomValue = (min = 30) => {
        let value = 0;
        while (value < min) {
            value = Math.ceil(Math.random() * 100);
        }
        return value;
    }

    const generateMapData = (sourceData) => {
        sourceData = sourceData || data;
        let axisPositions = [
            { value: [520, 180, 160], color: '#2562b7', fontSize: 26 }, //1
            { value: [363, 363, 150], color: '#2562b7', fontSize: 26 }, //2
            { value: [734, 323, 150], color: '#7799c7', fontSize: 26 }, //3
            { value: [840, 148, 160], color: '#8eaad0', fontSize: 26 }, //4
            { value: [204, 208, 136], color: '#7799c7', fontSize: 26 }, //5
            { value: [695, 50, 90], color: '#8eaad0', fontSize: 22}, //6
            { value: [268, 50, 100], color: '#bbcce2', fontSize: 22 }, //7
            { value: [576,406, 90], color: '#bbcce2', fontSize: 22 }, //8
            { value: [983, 287, 112], color: '#a5bbd9', fontSize: 22}, //9
            { value: [55, 120, 106], color: '#a5bbd9', fontSize: 22 }, //10
            { value: [169, 442, 100], color: '#bbcce2', fontSize: 22 }, //11
            { value: [817, 441, 88], color: '#bbcce2', fontSize: 18 }, //12
            { value: [1088, 380, 100], color: '#bbcce2', fontSize: 22 }, //13
            { value: [1008, 101, 88], color: '#8eaad0', fontSize: 18 }, //14
            { value: [20, 300, 100], color: '#a5bbd9', fontSize: 22 }, //15
            { value: [140, 340, 54], color: '#8eaad0', fontSize: 18 }, //16
            { value: [1088, 195, 60], color: '#8eaad0', fontSize: 18 }, //17
            { value: [1, 30, 80], color: '#7799c7', fontSize: 18 }, //18
            { value: [1088, 30, 80], color: '#bbcce2', fontSize: 18 }, //19
            { value: [389, 106, 50], color: '#8eaad0', fontSize: 18 }, //20
        ];
        let { cmsModuleInfoVos = [] } = sourceData;
        let mapSourceData = cmsModuleInfoVos.map((item) => {
            return {
                type: item.imageFirstDesc,
                ...item,
            }
        });
        mapSourceData = mapSourceData.splice(0, 20);
        let baseMapData = [];
        mapSourceData.forEach((data, index) => {
            baseMapData.push({
                id: data.id,
                name: data.type,
                value: axisPositions[index].value,
                itemStyle: {
                    color: axisPositions[index].color,
                },
                label: { show: true, formatter: data.type, color: '#fff', fontSize: axisPositions[index].fontSize }
            })
        })
        return baseMapData;
    }
    const generateChartOption = (baseData, activeData) => {
        if (!activeData) {
            activeData = baseData[0];
            activeData.value[2] = baseData[0].value[2]*OVER;
            baseData.splice(0, 1)
        }
        return {
            backgroundColor: '#FFF',
            xAxis: {
                min: 0,
                max: 1090,
                show: false
            },
            yAxis: {
                min: 0,
                max: 545,
                show: false
            },
            series: [
                {
                    name: 'base',
                    symbolSize: function (data) {
                        console.log(data)
                        return data[2];
                    },
                    data: baseData,
                    type: 'scatter',
                    itemStyle: {
                        color: '#7799c7',
                        shadowBlur: 10,
                        shadowColor: 'rgba(82, 115, 159,0.8)',
                        shadowOffsetY: 5,
                        // shadowColor: '#0654A1',
                        // shadowBlur: 20,

                    },
                    
                   
                },
            ],
            animation:true,
     
        }
    }
    const getSourceDataActiveItem = (sourceData, id) => {
        return sourceData.cmsModuleInfoVos.find((item) => {
            return item.id == id
        })
    }
    const initChart = (sourceData) => {
        let containerDom = mapRef.current;
        myChart = echarts.init(containerDom);
        myChart.clear();
        myChart.off();
        let baseData = generateMapData(sourceData);
        let activeData = baseData[0];
        // activeData.value[2] = 150;
        // baseData.splice(0,1)
        baseData[0] = {
            ...activeData,
            value: [activeData.value[0], activeData.value[1], (activeData.value[2]*OVER)],
            itemStyle: {
                // opacity: 0,
                color: activeColor
            },
            label: {
                ...activeData.label,
                fontSize: activeFontSize
            }
        }
        let option = generateChartOption(baseData, [activeData]);
        setActiveItem(getSourceDataActiveItem(sourceData, activeData.id) || {})
        myChart.setOption(option);
        myChart.on('mouseover', 'series', (clickedItem) => {
            let baseData = generateMapData(sourceData);
            let { name, value, id } = clickedItem.data;
            let clickedItemIndex = baseData.findIndex((item) => {
                return item.id == id
            })
            let activeData = baseData[clickedItemIndex];
            // baseData.splice(clickedItemIndex,1);
            baseData[clickedItemIndex] = {
                ...activeData,
                value: [activeData.value[0], activeData.value[1], (activeData.value[2]*OVER)],
                itemStyle: {
                    color: activeColor,
                },
                label: {
                    ...activeData.label,
                    fontSize: activeFontSize
                }
            }
            let newActiveSeryData = {
                id,
                name,
                value: [value[0], value[1], (value[1]*OVER)], //activeSeryData.value,
                label: { show: true, formatter: name, color: '#fff', fontSize: activeFontSize }
            }
            // let newBaseData = removeActiveFromBase(base, clickedItem.name);
            let newOptions = generateChartOption(baseData, [newActiveSeryData]);
            setActiveItem(getSourceDataActiveItem(sourceData, id) || {})
            myChart.setOption(newOptions);
        });
    }
    const parseDescToArray = (str)=>{ //区分中英文分号
        while(new RegExp('；').test(str)){
            str = str.replace('；',';');
        }
        if(/\;$/.test(str)){
            str = str.substr(0,str.length-1)
        }
        return str.split(';')
    }
    const renderSecondImageDesc = (str) => {
        let res = str ? parseDescToArray(str) : [];
        return res.map((item, index) => {
            return <div key={index} className="detail-item">{item}</div>
        })
    }
    const showPrev = () => {
        let { cmsModuleInfoVos = [] } = data;
        let activeItemIndex = getActiveItemIndex();
        let nextActiveIndex; 
        if(activeItemIndex==0){
            nextActiveIndex = cmsModuleInfoVos.length-1;
        } else {
            nextActiveIndex = activeItemIndex-1;
        }
        updateMap(nextActiveIndex)
    }
    const showNext = () => {
        let { cmsModuleInfoVos = [] } = data;
        let activeItemIndex = getActiveItemIndex();
        let nextActiveIndex; 
        if(activeItemIndex==(cmsModuleInfoVos.length-1)){
            nextActiveIndex = 0;
        } else {
            nextActiveIndex = activeItemIndex+1;
        }
        updateMap(nextActiveIndex)
    }
    const updateMap = (nextActiveIndex)=>{
        let { cmsModuleInfoVos = [] } = data;
        let baseData = generateMapData(data);
        let nextActiveData = baseData[nextActiveIndex]
        baseData[nextActiveIndex] = {
            ...nextActiveData,
            value: [nextActiveData.value[0], nextActiveData.value[1], (nextActiveData.value[2]*OVER)],
            itemStyle: {
                color: activeColor,
            },
            label: {
                ...nextActiveData.label,
                fontSize: activeFontSize
            }
        }
        let newOptions = generateChartOption(baseData, [nextActiveData]);
        setActiveItem(getSourceDataActiveItem(data, cmsModuleInfoVos[nextActiveIndex].id) || {})
        myChart.setOption(newOptions)
    }
    const getActiveItemIndex = ()=>{
        let { cmsModuleInfoVos = [] } = data;
        return cmsModuleInfoVos.findIndex((item)=>{
            return item.id == activeItem.id
        })
    }
    if (!showModule) {
        return null;
    }
    return (
        <div className="home-section industry-scatter">
            <div className="fix-width">              
                <div className="scatter-map" ref={mapRef} id="scatterMap"></div>
                <div className="industry-detail">
                    <div className="flex-container">
                        <div className="industry-desc">
                            <div className="title">{activeItem.imageFirstDesc}</div>
                            <div className="details">
                                {renderSecondImageDesc(activeItem.imageSecondDesc)}
                            </div>
                        </div>
                        {activeItem.images && activeItem.images.map((url) => {
                            return (
                                <div className="industry-image" key={url}>
                                    <img src={url} />
                                </div>
                            )
                        })}
                    </div>
                    <NavigationArrow showPrev={showPrev} showNext={showNext} />
                </div>
            </div>
        </div>
    )
}

export const ProductService = (props) => {
    const [showModule, setShowModule] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        let moduleData = getModuleData(props.pageData, 4);
        setShowModule(moduleData.isCheck == 1)
        setData(moduleData);
    }, [props.pageData])
    if (!showModule) {
        return null;
    }
    return (
        <div className="home-section product-service">
            <div className="fix-width">
                <div className="title">{data.title}</div>
                <div className="details">
                    {data.cmsModuleInfoVos && data.cmsModuleInfoVos.map((item, index) => {
                        return (<div className="detail-item" key={index}>
                            <img src={item.images ? item.images[0] : null} alt="icon" />
                            <div className="item-title">{item.imageFirstDesc}</div>
                            <div className="item-desc text-ellipsis">{item.imageSecondDesc}</div>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

export const ProfessionalManage = (props) => {
    const [activeItem, setActiveItem] = useState({});
    const [data, setData] = useState([]);
    useEffect(() => {
        if (props.pageData) {
            setData(props.pageData);
            setActiveItem(props.pageData[0] || {})
        }
    }, [props.pageData])
    return (
        <div className="home-section professional-manage">
            <div className="fix-width">
                <div className="title">{data[0] ? data[0].title : ''}</div>
                <div className="details">
                    {data.map((item, index) => {
                        return (<div key={index} onMouseOver={() => { setActiveItem(item) }} className={`detail-item ${activeItem.id == item.id ? 'selected' : 'not-selected'}`} >
                            <img src={item.images ? item.images[0] : null} />
                            <div className="item-title">{item.imageFirstDesc}</div>
                            <div className="item-active-border"></div>
                        </div>)
                    })}
                </div>
                <div className="active-detail-item-demo">
                    {activeItem.complexImagesDtos && activeItem.complexImagesDtos.map((item, index) => {
                        return (<div className="demo-item" key={item.id}>
                            <img src={item.images ? item.images[0] : null}  />
                            <div className="desc-text text-ellipsis">{item.imageFirstDesc}</div>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

export const MapImage = (props) => {
    const [showModule, setShowModule] = useState(false);
    const [data, setData] = useState({});
    useEffect(() => {
        let moduleData = getModuleData(props.pageData, 6);
        setShowModule(moduleData.isCheck == 1)
        let newData = { title: moduleData.title };
        let cmsModules = moduleData.cmsModuleInfoVos;
        if (cmsModules && cmsModules.length > 0) {
            let images = cmsModules[0].images;
            if (images && images.length > 0) {
                newData.image = images[0];
            }
        }
        setData(newData);
    }, [props.pageData])
    if (!showModule) {
        return null;
    }
    return (
        <div className="home-section map-image">
            <div className="fix-width map-container" style={{ backgroundImage: `url(${data.image})`}}>
                <div className="title">{data.title}</div>
            </div>
        </div>
    )
}

export const BusinessParterner = (props) => { //合作伙伴
    const [showModule, setShowModule] = useState(false);
    const [partnerGroup, setPartnerGroup] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [title, setTitle] = useState(null);
    useEffect(() => {
        let moduleData = getModuleData(props.pageData, 7);
        setShowModule(moduleData.isCheck == 1)
        setTitle(moduleData.title);
        if (moduleData.cmsModuleInfoVos) {
            let cmsModules = [].concat(moduleData.cmsModuleInfoVos[0].images);
            let groupData = [];
            while (cmsModules.length >= 8) {
                let trunk = cmsModules.splice(0, 8);
                groupData.push(trunk);
            }
            if (cmsModules.length > 0) {
                groupData.push(cmsModules)
            }
            setPartnerGroup(groupData)
        }
    }, [props.pageData])
    const showPrev = () => {
        let newIndex;
        if (activeIndex == 0) {
            newIndex = partnerGroup.length - 1
        } else {
            newIndex = activeIndex - 1
        }
        setActiveIndex(newIndex)
    }
    const showNext = () => {
        let newIndex;
        if (activeIndex == partnerGroup.length - 1) {
            newIndex = 0
        } else {
            newIndex = activeIndex + 1
        }
        setActiveIndex(newIndex)
    }
    let partnersInDisplay = partnerGroup[activeIndex];
    if (!showModule) {
        return null;
    }
    return (
        <div className="home-section business-partners">
            <div className="fix-width">
                <div className="title">{title}</div>
                <div className="partner-list-container">
                    <div className="partner-list">
                        {partnersInDisplay && partnersInDisplay.map((imageUrl, index) => {
                            return (
                                <div className='partner-item'>
                                    <img key={`${imageUrl}${index}`} src={imageUrl} alt="合作伙伴logo " />
                                </div>
                            )
                        })}
                    </div>
                    {partnerGroup.length > 1 && <NavigationArrow showPrev={showPrev} showNext={showNext} />}
                </div>
            </div>
        </div>
    )
}

