/**关于我们页面 - 公司简介*/
import { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import './index.less';
import { getPageData } from './../../../util/utils'
import { getBannerImage } from '../../warehouse-for-rent';
import { getModuleData } from '../../home/sections';
import { SlideBanner } from './../../home/sections';
function CompanyIntro(props) {
    const [pageData, setPageData] = useState({
        bannerData: {},
        configData: {},
        companyValuesData: { images: [] }
    })
    useEffect(() => {
        getPageData({
            pageType: 5,
            lang: props.intl.locale
        }).then((res) => {
            let { code, data, msg } = res;
            if (code == 200) {
                if (data && data.length > 0) {
                    let bannerData = getModuleData(data, 1),
                        configData = getModuleData(data, 2),
                        companyValuesData = getModuleData(data, 3);
                    setPageData({
                        bannerData: {
                            showModule: bannerData.isCheck == 1,
                            ...bannerData
                        },
                        configData: {
                            showModule: configData.isCheck == 1,
                            title: configData.title,
                            image: getBannerImage(configData)
                        },
                        companyValuesData: {
                            showModule: companyValuesData.isCheck == 1,
                            title: companyValuesData.title,
                            images: getImages(companyValuesData)
                        }
                    })
                }
            }
        }).catch((res) => {
            console.log(res);
        })
    }, [props.intl.locale])
    const getImages = (moduleData) => {
        let res = [];
        let moduleItems = moduleData.cmsModuleInfoVos;
        if (moduleItems && moduleItems.length > 0) {
            res = moduleItems[0].images || [];
        }
        return res;
    }
    return (
        <div className="company-intro">
            {pageData.bannerData.showModule && <div className="section page-banner">
                <SlideBanner data={pageData.bannerData} />
            </div>}
            {pageData.configData.showModule && <div className="section config-bg" >
                <div className="fix-width config-bg-container" style={{ backgroundImage: `url(${pageData.configData.image})` }}></div>
            </div>}
            {pageData.companyValuesData.showModule && <div className="section company-values" >
                <div className="fix-width">
                    <div className="section-title">{pageData.companyValuesData.title}</div>
                    <div className="value-list">
                        {[1, 2, 3].map((item, index) => {
                            return (
                                <div className="value-item" key={index} style={{ backgroundImage: `url(${pageData.companyValuesData.images[index]})` }}></div>
                            )
                        })}
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default injectIntl(CompanyIntro, { withRef: true });

