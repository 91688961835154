import React, { useState, useEffect } from 'react';
import './index.less'
const Slide = (props) => {
    const { data = [], slideTemplate, onClick } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [slideTimer, setSlideTimer] = useState();
    let activeIndexInTimer = 0; //定时器方法内读取activeIndex的值有问题，因此同步存到此变量
    useEffect(() => {
        if(props.data.length>1){
            createInterval();
        }
        return () => { removeInterval(); }
    }, [props.data])

    const createInterval = () => {
        // setSlideTimer(setInterval(updateActiveIndex, 2000))
        setSlideTimer(setInterval(updateActiveIndex, 10000))
    }
    const removeInterval = () => {
        clearInterval(slideTimer);
    }
    const updateActiveIndex = () => {
        if (activeIndexInTimer == data.length - 1) {
            activeIndexInTimer = 0;
        } else {
            activeIndexInTimer++;
        }
        setActiveIndex(activeIndexInTimer)
    }
    const hoverDot = (index) => {
        activeIndexInTimer = index;
        setActiveIndex(activeIndexInTimer)
    }
    const clickSlide = (item) => {
        let { onClick } = props;
        if (onClick && typeof onClick == 'function') {
            onClick(item)
        }
    }
    const renderSlideItems = () => {
        return data.map((item, index) => {
            let SlideItemTemplate = slideTemplate;
            return (
                <div className={`slide-item-container ${index == activeIndex ? 'active' : 'inactive'}`} onClick={() => { clickSlide(item) }} key={index}>
                    <SlideItemTemplate data={item} />
                </div>
            )
        })
    }
    const renderDots = () => {
        let dots;
        if (data.length > 1) {
            dots = data.map((item, index) => {
                return <span className={activeIndex === index ? "dot active" : "dot"} key={index} data-index={index} onMouseOver={() => { hoverDot(index) }}></span>
            })
        } else {
            dots = null;
        }
        return dots;
    }
    const onMouseEnter = (e) => {
        removeInterval()
    }
    const onMouseLeave = (e) => {
        e.preventDefault();
        createInterval()
    }

    return (
        <div className="slides-container"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {renderSlideItems()}
            <div className="dots-container"> {renderDots()} </div>
        </div>
    )
}

export default Slide;